import React, { useEffect } from "react";
import styles from "./chatbot.module.css";
import ChatbotMessageSend from "./ChatbotMessageSend";
import { FaAngleDown, FaEllipsisV } from "react-icons/fa";
import ChatbotMessages from "./ChatbotMessages";
import Loader from "../custom/Loader";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import useChatMessages from "./hooks/useChatMessages";
import { chatImg, liveIcon, logo } from "../../constants/images";

export default function Chatbot({ chatId, onClose, sessionId, connectToSocket, socketNewMessage, isChatClose, login_user, userImage, }) {
  const {
    currentChatId,  hasChatCreated,  createType,  createChat,  messages,
    setMessages, loading, chatLoading, setchatLoading,
    isBotWorking,setIsBotWorking,chatClose,setChatClose,handleCloseChat,ValidateKey,handleRating,handleEmojiSelect,onChatMsgSubmit,
    handleCreateChat,chatBotAdminData,isAddress,stopSendloading,
  } = useChatMessages(sessionId, login_user);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    ValidateKey(chatId);
  }, []);

  useEffect(() => {
    if (currentChatId) {
      console.log("Connecting on API Response", currentChatId);
      connectToSocket(currentChatId);
    } else if (chatId) {
      console.log("Connect the socket on page load");
      const chatDataStr = localStorage.getItem(chatId);
      if (chatDataStr) {
        const chatBotConnectid = JSON.parse(chatDataStr)._id;
        connectToSocket(chatBotConnectid);
      }
    } else {
      console.log("Error count not connect the socket.");
    }
  }, [hasChatCreated]);

  useEffect(() => {
    if (socketNewMessage) {
      if (isChatClose) {
        setChatClose((prev) => isChatClose);
      }
      setMessages((prevMessages) => [
        ...prevMessages,
        socketNewMessage?.userMessageNew || socketNewMessage?.userMessage,
      ]);
    }
  }, [socketNewMessage]);

  return (
    <div className={styles.all_chatpart_design}>
      <div className={styles.all_chatpart_header}>
        <div className={styles.with_avtar}>
          <img src={userImage || chatImg} alt="" />
          Hii there !
        </div>
        <div className={styles.more_Option}>
          <div onClick={handleClick} className={styles.donw_arrow}>
            <FaEllipsisV />
          </div>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{   vertical: "bottom",   horizontal: "left", }}
            transformOrigin={{ vertical: "top", horizontal: "center",}}
            style={{ zIndex: 999999 }}>
            <Typography
              sx={{ px: 2, py: 1, cursor: "pointer" }}
              onClick={() => handleCloseChat(chatId, onClose, handleClose)}>
              Close Chat
            </Typography>
          </Popover>
          <div onClick={onClose} className={styles.donw_arrow}>
            <FaAngleDown />
          </div>
        </div>
      </div>
      <div className={styles.we_online_box}>
        <img src={liveIcon} alt="" /> We are online
      </div>
      {loading ? (
        <div className={styles.all_chatpart_body}>
          <Loader size="medium" />
        </div>
      ) : (
        <ChatbotMessages
          chatId={chatId}
          setIsBotWorking={setIsBotWorking}
          messages={messages}
          chatLoading={chatLoading}
          setchatLoading={setchatLoading}
          setMessages={setMessages}
          setChatClose={setChatClose}
          handleCreateChat={handleCreateChat}
          createChat={createChat}
          onChatMsgSubmit={onChatMsgSubmit}
          createType={createType}
          chatBotAdminData={chatBotAdminData}
          stopSendloading={stopSendloading}
          onClose={onClose}
          chatClose={chatClose}
          handleRating={handleRating}
        />
      )}
      <ChatbotMessageSend
        onClose={onClose}
        messages={messages}
        isBotWorking={isBotWorking}
        chatClose={chatClose}
        chatId={chatId}
        handleRating={handleRating}
        handleEmojiSelect={handleEmojiSelect}
        onChatMsgSubmit={onChatMsgSubmit}
        handleCreateChat={handleCreateChat}
        createChat={createChat}
        isAddress={isAddress}
        stopSendloading={stopSendloading}
      />
      <div className={styles.powered_box}>
        Powered By <img src={logo} alt="" /> <span>Propertyjinni</span>
      </div>
    </div>
  );
}
