import { useCallback, useState } from "react";
import Axios from "../../../utils/axios";
import { debounce } from "../../../utils/commonHelp";

const useAddress = () => {
  const [placesData, setPlacesData] = useState([]);
  const [showList, setShowList] = useState(false);
  const [error, setError] = useState("");

  const fetchPlacesList = (place) => {
    if (place === "") {
      setShowList(false);
      setPlacesData([]);
      return 0;
    }
    Axios.get(`/places/address-autocomplete?text=${place}`)
      .then((res) => {
        setPlacesData(res?.data?.data);
        setShowList(true);
      })
      .catch((err) => {
        setPlacesData([]);
        setError(err);
      });
  };

  const handleLocationClick = (location, setMsg) => {
    setMsg(location.description);
    setPlacesData([]);
    setShowList(false);
  };

  const userTxtChangeDebounce = useCallback(
    debounce(
      (txtval, handleClick1) => fetchPlacesList(txtval, handleClick1),
      800
    ),
    []
  );

  const onChangeAddress = (e) => {
    userTxtChangeDebounce(e.target.value);

   
  };
  return {
    fetchPlacesList,
    onChangeAddress,
    handleLocationClick,
    placesData,
    showList,
    error,
    setShowList
  };
};
export default useAddress;
