import axios from "axios";
import { API_BASE_URL } from "../constants/constants";
const Axios = axios.create({
  baseURL: API_BASE_URL,
});

// Adding authorization token in request header
Axios.interceptors.request.use((config) => {
  return config;
});

Axios.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      error.response.data.error ||
        error.response.data.message ||
        "Something went wrong"
    )
);

export default Axios;
