import React from "react";
import ChatBotIndex from "./components/Chatbot/index";
import useSocket from "./components/hooks/useSocket";
import "./index.css";
const App = ({ bot_id, login_user }) => {
  const {
    connectSocket,
    newSocketMessage,
    isChatClose,
    setChatClose,
    setSocketNewMessage,
  } = useSocket();

  const connectToSocket = (chatBotConnectid) => {
    connectSocket(chatBotConnectid, () => {});
  };

  const clearSocketState = () => {
    setChatClose(false);
    setSocketNewMessage(null);
  };

  return (
    <>
      <ChatBotIndex
        chatId={bot_id}
        connectToSocket={connectToSocket}
        socketNewMessage={newSocketMessage}
        isChatClose={isChatClose}
        clearSocketState={clearSocketState}
        login_user={login_user}
      />
    </>
  );
};

export default App;
