import React from "react";
import ReactDOM from "react-dom/client";
import PropTypes from "prop-types";
import reactToWebComponent from "react-to-webcomponent";

import App from "./app.js"; // eslint-disbale-line

App.propTypes = {
  bot_id: PropTypes.string.isRequired,
  login_user: PropTypes.string.isRequired,
};

customElements.define(
  "react-support",
  reactToWebComponent(App, React, ReactDOM)
);
