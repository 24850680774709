import React, { useState, useEffect } from "react";
import { Typography, Paper, Box, Button, Grid } from "@mui/material";
import Axios from "../../utils/axios";
import styles from "../Chatbot/chatbot.module.css";
import moment from "moment";
import { IoIosArrowBack } from "react-icons/io";
import Loader from "./Loader";

const BotCal = ({
  handleSendDailogApi,
  chatId,
  chatBotAdminData,
  stopSendloading,
  messages,
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [slotsData, setSlotsData] = useState([]);
  const [dateLoad, setDateLoad] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setDateLoad(true);
      try {
        const params = {
          userid: chatBotAdminData.userId,
          isAgency: true,
          department: messages?.slice(-1)[0]?.category,
        };

        const response = await Axios.get(`/todo/get-slots-availability`, {
          params,
        });

        if (response.data.data) {
          setDateLoad(false);
          const currentDateTime = moment(response.data.currentDateTime);
          const filteredSlotsData = response.data.data.map((dateObj) => {
            return {
              date: dateObj.date,
              slots: dateObj.slots
                // .filter(slot => moment(`${dateObj.date} ${slot.endTime}`, 'MM/DD/YYYY hh:mm A').isAfter(currentDateTime))
                .filter(
                  (slot) =>
                    moment(
                      `${dateObj.date} ${slot.endTime}`,
                      "MM/DD/YYYY hh:mm A"
                    ).isAfter(currentDateTime) &&
                    moment(
                      `${dateObj.date} ${slot.startTime}`,
                      "MM/DD/YYYY hh:mm A"
                    ).isAfter(currentDateTime)
                )
                .slice(),
            };
          });
          setSlotsData(filteredSlotsData);
        }
      } catch (error) {
        setDateLoad(false);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleDateClick = (date) => {
    setSelectedDate(date);
    setSelectedSlot(null);
  };

  const handleSlotClick = (slot) => {
    if (slot.slotAvailable) {
      setSelectedSlot(slot);
    }
  };

  return (
    <>
      {!dateLoad ? (
        <div className={styles.calanedar_container}>
          {/* Date Section */}
          <div
            hidden={selectedDate}
            style={{
              boxShadow: " 0px 0px 12.100000381469727px 0px #0000001C",
            }}>
            <Typography
              variant="p"
              sx={{
                padding: 1,
                height: "25px",
                lineHeight: "30px",
                paddingBottom: "0",
                fontWeight: "700",
                marginTop: "4px",
              }}>
              Choose a date
            </Typography>
            <Box
              sx={{
                overflowY: "auto",
                height: "250px",
                // borderTop: "1px solid gray",
              }}>
              {slotsData.map((dateObj) => (
                <Paper
                  key={dateObj.date}
                  elevation={3}
                  onClick={() => handleDateClick(dateObj.date)}
                  sx={{
                    padding: 1,
                    // margin: 1,
                    cursor: "pointer",
                    backgroundColor:
                      selectedDate === dateObj.date ? "#cfe8fc" : "inherit",
                    boxShadow: "none",
                    borderBottom: "1px solid #efefef",
                    borderRadius: "0px",
                    "&:hover": {
                      backgroundColor: "#ac6dfd", // Change the background color on hover
                      color: "#ffffff",
                    },
                  }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      fontSize: "14px",
                    }}>
                    <div>{moment(dateObj.date).format("dddd")}</div>
                    <div>
                      {moment(dateObj.date).format("MMMM D, YYYY")}
                    </div>{" "}
                  </Typography>
                </Paper>
              ))}
            </Box>
          </div>

          <div
            hidden={!selectedDate}
            style={{
              boxShadow: " 0px 0px 12.100000381469727px 0px #0000001C",
            }}>
            <Typography
              variant="p"
              sx={{
                padding: 0,
                height: "40px",
                lineHeight: "30px",
                // paddingLeft: 1,
                display: "flex",
                alignItems: "center",
                color: "#9949ff",
                // justifyContent: "space-between",
                fontWeight: "800",
              }}>
              <span
                onClick={() => {
                  setSelectedDate(null);
                  setSelectedSlot(null);
                }}
                style={{
                  color: "#9949ff",
                }}>
                <IoIosArrowBack
                  style={{
                    width: "15px",
                    fontSize: "15px",
                    height: "15px",
                    margin: "8px",
                    marginBottom: "0",
                    color: "#9949ff",
                    cursor: "pointer",
                  }}
                />
              </span>
              {moment(selectedDate).format("dddd, MMMM D")}{" "}
            </Typography>

            {selectedDate && (
              <Box
                sx={{
                  overflowY: "auto",
                  height: "250px",
                  overflowX: "hidden",
                  // borderTop: "1px solid gray",
                }}>
                <Grid container spacing={0} className="mt-2">
                  {slotsData
                    .find((dateObj) => dateObj.date === selectedDate)
                    ?.slots.map((slot, idx) => (
                      <>
                        <Grid
                          xs={12}
                          sm={12}
                          md={6}
                          sx={{
                            borderRight: "1px solid #efefef",
                            borderBottom: "1px solid #efefef",
                            display: !slot.slotAvailable ? "none" : "",
                          }}>
                          <Button
                            key={`${slot.startTime}-${slot.endTime}`}
                            onClick={() => {
                              if (!stopSendloading) {
                                handleSlotClick(slot);
                                handleSendDailogApi(
                                  moment(
                                    selectedDate + " " + slot.startTime
                                  ).format(),
                                  chatId,
                                  true
                                );
                              }
                            }}
                            disabled={stopSendloading}
                            hidden={!slot.slotAvailable}
                            sx={{
                              padding: 1,
                              backgroundColor: slot.slotAvailable
                                ? "inherit"
                                : "#eee",

                              width: "100%",
                              display: !slot.slotAvailable ? "none" : "",
                              color: "#000",
                              "&:hover": {
                                backgroundColor: "#ac6dfd", // Change the background color on hover
                                color: "#ffffff",
                                borderRadius: "0",
                              },
                            }}>
                            {`${slot.startTime}`}
                          </Button>
                        </Grid>
                      </>
                    ))}
                </Grid>
              </Box>
            )}
          </div>
        </div>
      ) : (
        <Loader size="medium" />
      )}
    </>
  );
};

export default BotCal;
