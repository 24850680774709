import React, { useEffect, useRef, useState } from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import styles from "./chatbot.module.css";
import BotCal from "../custom/BotCal.jsx";
import { sendChatIcon } from "../../constants/images.js";

const ChatbotMessages = ({
  chatId, messages, chatLoading, handleCreateChat, createChat, onChatMsgSubmit, createType,
  chatBotAdminData, stopSendloading, chatClose, onClose, handleRating,
}) => {
  const isFormAlreadySubmitted = JSON.parse(localStorage.getItem(chatId));
  const chatContainerRef = useRef(null);
  const [msg, setMsg] = useState("");

  useEffect(() => {
    // Scroll to the latest message when new messages are added
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);



  return (
    <div className={styles.all_chatpart_body} ref={chatContainerRef}>
      {messages?.map((message, index) => {
        const isUserMessage =
          message?.sender === isFormAlreadySubmitted?._id ||
          message?.sender == null;
        return (
          <>
            <div
              className={
                isUserMessage || message?.sender === "you"
                  ? `${styles.sender_box} ${styles.sender_right_side}`
                  : styles.sender_box
              }>
              {isUserMessage || message?.sender === "you" ? (
                <>
                  You{" "}
                  <span>
                    {isFormAlreadySubmitted?.name?.slice(0, 1) || "y"}
                  </span>
                </>
              ) : (
                <>
                  <img src={sendChatIcon} alt="" />
                  {message?.sender?.name || "Bot"}
                </>
              )}{" "}
            </div>
            <div
              key={message?._id}
              className={
                isUserMessage || message?.sender === "you"
                  ? `${styles.box} ${styles.right_side}`
                  : styles.box
              }>
              <div
                dangerouslySetInnerHTML={{
                  __html: message?.message
                    ? message?.message?.replace(/\n/g, " <br/> ")
                    : "",
                }}></div>
            </div>
            {message?.field === "dailog_select" && message?.value !== "" && (
              <Stack
                direction="row "
                sx={{ flexWrap: "wrap" }}
                spacing={2}
                className={styles.sender_right_side}>
                {message.options?.map((data, index) => {
                  return (
                    <span
                      style={{ margin: "3px 2px" }}
                      className={message?.value === data && styles.slectedChip}>
                      <Chip
                        label={data}
                        variant={message?.value === data ? "filled" : "outlined"}
                      />
                    </span>
                  );
                })}
              </Stack>
            )}
          </>
        );
      })}
      {messages?.[messages.length - 1]?.field === "dailog_select" &&
        messages?.[messages.length - 1]?.value === "" && (
          <Stack
            direction="row "
            sx={{ flexWrap: "wrap" }}
            spacing={2}
            className={styles.sender_right_side}>
            {messages?.[messages.length - 1].options?.map((data, index) => {
              return (
                <span style={{ margin: "3px 2px" }}>
                  <Chip
                    label={data}
                    variant={
                      messages?.[messages.length - 1].value === data
                        ? ""
                        : "outlined"
                    }
                    onClick={(e) => {
                      if (chatClose) {
                        handleRating(
                          data,
                          setMsg,
                          onClose,
                          isFormAlreadySubmitted,
                          chatId
                        );
                      } else {
                        if (!createChat) {
                          handleCreateChat(data, chatId);
                        } else {
                          onChatMsgSubmit(
                            e,
                            data,
                            chatId,
                            isFormAlreadySubmitted,
                            setMsg
                          );
                        }
                      }
                    }}
                  />
                </span>
              );
            })}
          </Stack>
        )}

      {(createType === "arrange-callback" ||
        createType === "property-viewing" ||
        createType === "schedule-valuation") && (
        <div className={styles.sender_right_side} style={{ display: "flex" }}>
          <BotCal
            handleSendDailogApi={handleCreateChat}
            chatId={chatId}
            chatBotAdminData={chatBotAdminData}
            stopSendloading={stopSendloading}
            messages={messages}
          />
        </div>
      )}

      {chatLoading && (
        <div className={styles.chat_bubble}>
          <div className={styles.typing}>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatbotMessages;
