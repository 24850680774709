import React, { useRef, useState } from "react";
import styles from "./chatbot.module.css";
import Popover from "@mui/material/Popover";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import useAddress from "./hooks/useAddress";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { emoji, sendIcon } from "../../constants/images";

const ChatbotMessageSend = ({
  messages,
  chatId,
  isBotWorking,
  chatClose,
  onClose,
  handleEmojiSelect,
  onChatMsgSubmit,
  handleRating,
  handleCreateChat,
  createChat,
  isAddress,
  stopSendloading,
}) => {
  const [msg, setMsg] = useState("");

  const { onChangeAddress, placesData, showList, setShowList } = useAddress();

  const isFormAlreadySubmitted = JSON.parse(localStorage.getItem(chatId));
  const textareaRef = useRef();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  function handleKeyDown(e) {
    if (
      e.key === "Enter" &&
      isBotWorking &&
      msg &&
      messages?.[messages.length - 1]?.field !== "dailog_select" &&
      !stopSendloading
    ) {
      if (chatClose) {
        handleRating(msg, setMsg, onClose, isFormAlreadySubmitted,chatId);
      } else {
        if (!createChat) {
          handleCreateChat(msg, chatId);
          setMsg("");
        } else {
          onChatMsgSubmit(e, msg, chatId, isFormAlreadySubmitted, setMsg);
        }
      }
    }
  }
  return (
    <div className={styles.all_chatpart_footer + " d-flex"}>
      <img src={emoji} alt="" onClick={handleClick} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ zIndex: "9999999999" }}
      >
        <Picker
          data={data}
          onEmojiSelect={(emoji) =>
            handleEmojiSelect(emoji, textareaRef, setMsg, handleClose)
          }
          theme="light"
          emojiSize={18}
          previewPosition="none"
        />
      </Popover>

      {isAddress ? (
        <Autocomplete
          disablePortal
          open={showList && msg?.length > 2}
          id="select-on-focus"
          selectOnFocus
          disableClearable
          filterOptions={(options, state) => options}
          onChange={(event, newValue) => {
            setMsg(newValue?.description);
            setShowList(false);
          }}
          options={placesData}
          sx={{ width: 300, color: "#464749", p: 0, m: 0 }}
          getOptionLabel={(option) => option?.description}
          getOptionSelected={(option, value) =>
            option?.description === value?.description
          }
          renderOption={(props, option) => (
            <Box {...props} sx={{ padding: "0", margin: "0" }}>
              <span
                className={styles.placeList}
                style={{
                  padding: "0",
                  margin: "0",
                  width: "100%",
                  borderBottom: "none",
                }}
              >
                {option.description}
              </span>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              className={styles.address_input}
              placeholder="Search address ..."
              autoFocus={true}
              value={msg}
              onChange={(e) => {
                setMsg(e.target.value);
                if (isAddress && e.target.value.length > 2) {
                  onChangeAddress(e);
                } else {
                  setShowList(false);
                }
              }}
              onKeyPress={(e) => {
                handleKeyDown(e);
              }}
            />
          )}
        />
      ) : (
        <input
          type="text"
          ref={textareaRef}
          name=""
          id=""
          placeholder="Enter your message.."
          value={msg}
          onChange={(e) => {
            setMsg(e.target.value);
          }}
          onKeyPress={(e) => {
            handleKeyDown(e);
          }}
        />
      )}
      <div
        onClick={(e) => {
          if (
            isBotWorking &&
            msg &&
            !stopSendloading &&
            messages?.[messages.length - 1]?.field !== "dailog_select"
          ) {
            if (chatClose) {
              handleRating(msg, setMsg, onClose, isFormAlreadySubmitted,chatId);
            } else {
              if (!createChat) {
                handleCreateChat(msg, chatId);
                setMsg("");
              } else {
                onChatMsgSubmit(e, msg, chatId, isFormAlreadySubmitted, setMsg);
              }
            }
          }
        }}
      >
        <img className={styles.send_icon} src={sendIcon} alt="" />
      </div>
    </div>
  );
};

export default ChatbotMessageSend;
