import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const Loader = ({ size, color }) => {
  return (
    <div
      style={{
        width: "100%",
        height:
          size === "small" ? "40px" : size === "medium" ? "50px" : "180px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress
          style={{
            color: color ? color : "#3874ff",
            width:
              size === "small" ? "20px" : size === "medium" ? "40px" : "100px",
            height:
              size === "small" ? "20px" : size === "medium" ? "40px" : "100px",
          }}
        />
      </Box>
    </div>
  );
};

export default Loader;
