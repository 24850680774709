import { useState } from "react";
import Axios from "../../../utils/axios";
import { delayedExecution, isValidRating } from "../../../utils/validators";

const useChatMessages = (sessionId, login_user) => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chatLoading, setchatLoading] = useState(false);
  const [hasChatCreated, setChatCreated] = useState(false);
  const [currentChatId, setCurrentChatId] = useState("");
  const [isBotWorking, setIsBotWorking] = useState(false);
  const [chatClose, setChatClose] = useState(false);
  const [ratingData, setRatingData] = useState(false);
  const [createChat, setCreateChat] = useState(false);
  const [createType, setcreateType] = useState("");
  const [chatBotAdminData, setchatBotAdminData] = useState();
  const [stopSendloading, setStopSendloading] = useState(false);
  const [isAddress, setIsAddress] = useState(false);

  const handleCloseChat = (chatId, onClose, handleClose) => {
    let conversationData = localStorage.getItem(chatId);
    if (conversationData && !chatClose) {
      Axios.post("/botChat/closeChat", {
        chatConversationId: JSON.parse(conversationData)._id,
        owner: false,
      }).then((res) => {
        setChatClose(true);
        let message = {
          message: "Please provide rating for this conversation.",
          type: "rating",
          options: ["Good", "Average", "Bad"],
          sender: "bot",
          field: "dailog_select",
          value: "",
        };
        setMessages([...messages, message]);
        handleClose();
      });
    } else {
      onClose();
    }
  };

  const ValidateKey = async (chatId) => {

    setIsBotWorking(true);
    if (localStorage.getItem(chatId)) {
      let conversationData = localStorage.getItem(chatId);
      Axios.get(
        `/botChat/getChatBotMessage?conversationId=${JSON.parse(conversationData)._id
        }`
      )
        .then(async (response) => {
          if (response?.data?.data?.isActive) {
            setMessages(response?.data?.data?.chatMessages);
            setCreateChat(true);
          } else {
            localStorage.removeItem(chatId);

            setchatLoading(true);

            await delayedExecution();

            setMessages((prevMessages) => [
              ...prevMessages,
              {
                sender: "bot",
                type: "greeting",
                message:
                  "Welcome to PropertyJinni! How can I help you today?",
              },
            ]);

            setchatLoading(false);
          }
        })
        .catch((error) => {
          setchatLoading(false);
          console.error("Error fetching bot messages:", error);
        });
    } else {
      setchatLoading(true);
      await delayedExecution();

      setMessages((prevMessages) => [
        ...prevMessages,
        {
          sender: "bot",
          type: "greeting",
          message: "Welcome to PropertyJinni! How can I help you today?",
        },
      ]);

      setchatLoading(false);
    }
  };

  const handleSaveRating = (isFormAlreadySubmitted, rating) => {
    const ratingValue = rating === "good" ? 3 : rating === "average" ? 2 : 1;
    setchatLoading(true);
    const data = {
      rating: ratingValue,
      against: isFormAlreadySubmitted._id,
      user: isFormAlreadySubmitted.user_id,
      reviewFor: "chat bot",
    };

    Axios.post("/review/addReview", data).then((res) => {
      setRatingData(res.data.data);
      setchatLoading(false);
    });
  };

  const handlepUpdateReview = (review, onClose, chatId) => {
    let data = {
      reviewId: ratingData._id,
      comment: review,
    };
    setchatLoading(true);
    Axios.post("/review/updateReview", data).then((res) => {
      setIsBotWorking(false);
      setchatLoading(false);
      onClose();
      localStorage.removeItem(chatId);
    });
  };

  const handleRating = (msg, setMsg, onClose, isFormAlreadySubmitted, chatId) => {
    if (messages[messages.length - 1].type === "rating") {
      if (isValidRating(msg)) {
        handleSaveRating(isFormAlreadySubmitted, msg.toLowerCase());
        setMessages([
          ...messages.slice(0, messages.length),
          {
            sender: "you",
            type: "message",
            message: msg,
          },
          {
            sender: "bot",
            type: "review",
            message: "Please provide your review about our services.",
          },
        ]);
      } else {
        setMessages([
          ...messages.slice(0, messages.length),
          {
            sender: "you",
            type: "message",
            message: msg,
          },
          {
            sender: "bot",
            type: "rating",
            options: ["Good", "Average", "Bad"],
            message: "You can only provide rating.",
            field: "dailog_select",
            value: "",
          },
        ]);
      }
      setMsg("");
    } else {
      handlepUpdateReview(msg, onClose, chatId);
      setMessages([
        ...messages.slice(0, messages.length),
        {
          sender: "you",
          type: "message",
          message: msg,
        },
        {
          sender: "bot",
          type: "review",
          message: "Thankyou for sharing your review.",
        },
      ]);
      setMsg("");
    }
  };

  const updateChat = (isFormAlreadySubmitted, msg) => {
    setMessages([
      ...messages,
      { sender: isFormAlreadySubmitted?._id, message: msg },
    ]);
  };

  const onChatMsgSubmit = (e, msg, chatId, isFormAlreadySubmitted, setMsg) => {
    e.preventDefault();
    e.stopPropagation();
    setMsg("");
    setchatLoading(true);
    const data = {
      encryptedKey: chatId,
      chatId: isFormAlreadySubmitted._id,
      message: msg,
    };
    Axios.post(`/botChat/sendChatBotMessage`, data)
      .then((res) => {
        // Assuming the response contains the bot's message
        const botMessage = res.data.data.message;

        updateChat(isFormAlreadySubmitted, botMessage);
        setchatLoading(false);
      })
      .catch((err) => {
        setchatLoading(false);
      });
  };

  const handleCreateChat = (msg, chatId) => {
    setMessages([
      ...messages,
      { sender: "you", type: "message", message: msg },
    ]);
    setchatLoading(false);
    const data = {
      chatId: chatId,
      loginUser: login_user,
      message: msg,
    };
    Axios.post(`/botChat/createSupportChat`, data)
      .then((res) => {
        if (res.data?.chatData?._id) {
          if (res.data?.chatData && res.data?.chatData?._id) {
            setCurrentChatId((prev) => res.data?.chatData?._id);
          }
          setCreateChat(true);
          localStorage.setItem(
            chatId,
            JSON.stringify({
              _id: res.data?.chatData._id,
              user_id: res.data?.chatData.chatUserMetaData,
            })
          );
          setchatLoading(false);
          setChatCreated((prev) => true);
        }
      })
      .catch((err) => {
        setchatLoading(false);
      });
  };

  const handleEmojiSelect = (emoji, textareaRef, setMsg, handleClose) => {
    const cursorPos = textareaRef.current.selectionStart;

    setMsg((prevMsg) => {
      const start = prevMsg.substring(0, cursorPos);
      const end = prevMsg.substring(cursorPos);
      return `${start}${emoji.native}${end}`;
    });
    handleClose();
  };

  return {
    stopSendloading,
    createType,
    messages,
    setMessages,
    loading,
    setLoading,
    chatLoading,
    setchatLoading,
    isBotWorking,
    setIsBotWorking,
    ratingData,
    setRatingData,
    chatClose,
    setChatClose,
    handleCloseChat,
    ValidateKey,
    handleRating,
    handlepUpdateReview,
    handleSaveRating,
    handleEmojiSelect,
    onChatMsgSubmit,
    handleCreateChat,
    createChat,
    hasChatCreated,
    currentChatId,
    chatBotAdminData,
    isAddress,
  };
};
export default useChatMessages;
