import { useState, useEffect, useCallback } from "react";
import socketIOClient from "socket.io-client";

const useSocket = () => {
  const [socket, setSocket] = useState(null);
  const [connected, setConnected] = useState(false);

  const [isChatClose, setChatClose] = useState(false);
  const [newSocketMessage, setSocketNewMessage] = useState(null);

  const API_HOST = process.env.REACT_APP_API_HOST;

  const connectSocket = useCallback((chatBotConnectid, onConnectCallback) => {
    const socketInstance = socketIOClient(API_HOST, {
      transports: ["websocket", "polling"],
      reconnection: true,
      query: { id: chatBotConnectid, platform: "webbot" },
    });

    socketInstance.on("connect", () => {
      setConnected(true);
      console.log("Socket Connected");
    });

    socketInstance.on("disconnect", () => {
      setConnected(false);
      console.log("Socket Disconnected");
    });

    socketInstance.on("chatMessage", handleServerMessage);
    socketInstance.on("closeChat", handleChatClose);

    setSocket(socketInstance);
  }, []);;

  const handleServerMessage = useCallback((newMessage) => {
    setSocketNewMessage(newMessage);
  }, []);

  const handleChatClose = useCallback((newMessage) => {
    setChatClose(true);
    const message = {
      userMessage: {
        message: "Please provide rating for this conversation.",
        type: "rating",
        options: ["Good", "Average", "Bad"],
        sender: "bot",
        field: "dailog_select",
        value: ""
      },
    };
    setSocketNewMessage(message);
  }, []);

  useEffect(() => {
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [socket]);

  return {
    socket,
    connectSocket,
    connected,
    isChatClose,
    newSocketMessage,
    setChatClose,
    setSocketNewMessage,
  };
};

export default useSocket;
